<template>
    <div class="mx-auto" style="max-width: 1000px;">
            <Card class="card-custom">
                <template #title>
                    <div class="column-wrapper align-center">
                      <div class="column-three text-left"><back-button /></div>
                        <h4 class="c-black flex-1 column-three">{{$t('level_data')}}</h4>
                        <div class="column-three"></div>
                    </div>
                  </template>
              <template #content>
                <ValidationObserver ref="observer" v-slot="{ invalid, validate }">
                  <Skeleton width="100%" height="500px" v-if="loadingPage"></Skeleton>
                  <Card class="card-custom form-block" v-if="!loadingPage">
                                <template #title>
                                  <h4>
                                    {{ $t('sum_to_level') }}
                                  </h4>
                                  </template>
                                <template #content>
                                  <div class="space-y-1">
                                <ValidationProvider
                            :name="$t('currency')"
                            rules="required"
                            v-slot="{ errors, validate, validated }"
                          > 
                            <h4 class="c-text white-space title-input d-flex align-center">{{ $t('currency') }}
                                <span class="c-red">*</span>:</h4>
                            <div class="wrapper-input d-flex flex-column">
                              <div class="d-flex align-center">
                                  <MultiSelect
                                  disabled
                                v-model="selectedCurrencies"
                                :options="currencies"
                                class="custom-dropdown fw"
                                style="flex: 1 1 auto"
                                :class="{ 'p-invalid' : errors.length > 0 }"
                                :placeholder="$t('currency')"
                                :filter="true"
                                :emptyFilterMessage="$t('no_data')"
                              >
                              </MultiSelect>
                              <Badge value="!" severity="warning" class="warning-badge ml-1" v-tooltip.top="$t('level_currency_hint')"></Badge>
                              </div>
                              <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                            </div>
                        </ValidationProvider>
                        <span v-for="(limits, currency) in createLevel.next_level_amount" :key="currency">
                                  <h4 class="c-text white-space title-input">
                                    {{ currency }}:
                                  </h4>
                                    <div class="d-flex md-flex-column gap-1 fw">
                                      <ValidationProvider
                                      tag="div"
                                      class="d-flex flex-column fw"
                                        :name="$t('bet')"
                                        rules="required"
                                        v-slot="{ errors }"
                                      >
                                      <InputNumber 
                                      locale="en-US"
                                      class="flex-auto"
                                      mode="currency"
                                            :currency="currency"
                                            currencyDisplay="code"
                                            :placeholder="$t('bet')"
                                            v-if="selectedCurrencies.includes(currency)"
                                            v-model="createLevel.next_level_amount[currency]"
                                            :step="1"
                                            :min="1"
                                          />
                                          <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                                      </ValidationProvider>
                                    </div>
                              </span>
                              <Divider />    
                
                <ValidationProvider
                    :name="$t('name')"
                    rules="required"
                    v-slot="{ errors, validate, validated }"
                  > 
                    <h4 class="c-text white-space title-input">{{ $t('name') }}<span class="c-red">*</span>:</h4>
                    <div class="wrapper-input">
                        <InputText 
                    class="p-inputtext-sm fw"
                    :class="{ 'p-invalid' : errors.length > 0 }"
                    :placeholder="$t('name')" 
                    v-model="createLevel.name" 
                    />
                      <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                    </div>
                </ValidationProvider>
                <ValidationProvider
                    :name="$t('description')"
                    rules="required"
                    v-slot="{ errors, validate, validated }"
                  > 
                    <h4 class="c-text white-space title-input">{{ $t('description') }} <span class="c-red">*</span>:</h4>
                    <div class="wrapper-input">
                        <InputText 
                    class="p-inputtext-sm fw"
                    :class="{ 'p-invalid' : errors.length > 0 }"
                    :placeholder="$t('description')" 
                    v-model="createLevel.description" 
                    />
                      <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                    </div>
                </ValidationProvider>
                <ValidationProvider
                    :name="$t('dep_bonus')"
                    rules=""
                    v-slot="{ errors, validate, validated }"
                  > 
                    <h4 class="c-text white-space title-input">{{ $t('dep_bonus') }}
                        :</h4>
                    <div class="wrapper-input d-flex flex-column">
                      <Dropdown
                    v-model="createLevel.dep_bonus_id"
                    :options="dep_bonuses"
                    optionValue="value"
                    optionLabel="text"
                    dataKey="value"
                    class="custom-dropdown wrap-dropdown fw"
                    style="flex: 1 1 auto"
                    :class="{ 'p-invalid' : errors.length > 0 }"
                    :placeholder="$t('choose_deposit_bonus')"
                    :filter="true"
                    :emptyFilterMessage="$t('no_data')"
                  >
                  </Dropdown>
                      <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                    </div>
                </ValidationProvider>
                <ValidationProvider
                    :name="$t('free_rounds')"
                    rules=""
                    v-slot="{ errors, validate, validated }"
                  > 
                    <h4 class="c-text white-space title-input">{{ $t('free_rounds') }}
                        :</h4>
                    <div class="wrapper-input d-flex flex-column">
                      <Dropdown
                    v-model="createLevel.free_rounds_bonus_id"
                    :options="dep_bonuses"
                    optionValue="value"
                    dataKey="value"
                    optionLabel="text"
                    class="custom-dropdown wrap-dropdown fw"
                    style="flex: 1 1 auto"
                    :class="{ 'p-invalid' : errors.length > 0 }"
                    :placeholder="$t('choose_freespins_bonus')"
                    :filter="true"
                    :emptyFilterMessage="$t('no_data')"
                  >
                  </Dropdown>
                      <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                    </div>
                </ValidationProvider>
                <transition name="error-message">
                    <span class="error-message _no-bonus" v-if="noBonusFlag">
                      {{ $t('require_bonus') }}
                    </span>
                  </transition>
                  <v-col style="font-size: 12px;" class="load-photo" @click="triggerInput">
                    <span v-if="logoLevelPreview === null"> {{ $t('logo_select') }}
                    </span>
                    <div class="d-flex flex-column align-center justify-center" v-if="logoLevelPreview !== null">
                      <img :src="logoLevelPreview"
                        style="object-fit: contain;width:220px;height:220px">
                      <!-- Delete Photo -->
                      <Button class="p-button-sm p-button-danger px-8 mt-2" icon="pi pi-trash"
                      @click.stop="deleteImage"
                      ></Button>
                    </div>
                  </v-col>
                  <!-- Limit Photo Exceed -->
                  <v-row class="mt-1" v-if="logoLevelExceed" style="color: var(--red)">
                    <v-col>
                      {{ $i18n.t('game_logo_exceed') }}
                    </v-col>
                  </v-row>
                  <!-- Input Photo -->
                  <input type="file" ref="imagePreview" accept="image/png, image/jpeg" style="display: none" id="create-level"
                    @change="changeLevelFile($event)">
                    <transition name="error-message">
                      <span class="error-message justify-end" v-if="noLogoFlag">
                        {{ $t('required_field') }}
                      </span>
                    </transition>
                <span> 
                    <h4 class="c-text white-space title-input">
                      {{ $t('active') }}: 
                    </h4>
                    <div class="d-flex flex-column">
                      <InputSwitch class="p-inputswitch-success" v-model="createLevel.is_active" />
                    </div>
                  </span>
                </div>
              </template>
                </Card>
                        <Button @click="handleSubmit(validate)" 
                :disabled="formLoading" 
                :loading="formLoading"
                :label="$t('create')"
                        icon="pi pi-save" class="p-button-sm p-button-success fw mt-2" />
                </ValidationObserver>
        </template>
        </Card>
        </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
export default {
    name: 'CreatePlayerLevel',
    computed: {
        ...mapGetters({
        currencies: 'getFilteredCurrencies',
        spinsBonuses: 'bonuses/getSpinsAllListBonuses',
      depositBonuses: 'bonuses/getDepositAllListBonuses',
        }),
    },
    watch: {
        selectedCurrencies(newCurrencies) {
            if (!this.createLevel.next_level_amount) {
                this.$set(this.spinsBonus, 'next_level_amount', {});
            }
            for (const currency in this.createLevel.next_level_amount) {
                if (!newCurrencies.includes(currency)) {
                    // delete from selectedCurrencies and next_level_amount
                    delete this.createLevel.next_level_amount[currency];
                }
            }
            newCurrencies.forEach(currency => {
                if (!this.createLevel.next_level_amount[currency]) {
                    this.$set(this.createLevel.next_level_amount, currency, 1);
                }
            });
        },
    },
    data() {
        return {
            selectedCurrencies: [
            ],
            loadingPage: false,
            formLoading: false,
            noLogoFlag: false,
            noBonusFlag: false,
            isAllCurrencies: false,
            logoLevelExceed: false,
            logoLevelPreview: null,
            selectedCurrencies: [
        ],
            createLevel: {
                  name: "",
                  next_level_amount: {
                  },
                  logo: "",
                  order: 0,
                  is_active: true,
                  dep_bonus_id: '',
                  free_rounds_bonus_id: '',
                  description: "",
                },
                fr_bonuses:[],
                dep_bonuses:[],
        }
    },
    beforeRouteEnter(to, from, next){
    next(vm => {
      vm.$store.commit('setPageName', 'create_level')
      vm.toAllCurrencies();
    });
    },
    async mounted() {
      this.loadingPage = true;
      await this.holdBonuses();
      this.toAllCurrencies();
      this.loadingPage = false;
    },
    methods: {
      toAllCurrencies() {
                // add currencies
                const currencies = Object.keys(this.currencies).map(currency => {
                    return this.currencies[currency]; 
                });
                this.selectedCurrencies = currencies;
        },
        // Get Levels
    async getLevels(){
        await this.$store.dispatch("levels/awaitGetLevels", {filters: this.filters});
    },
    //========================================================================================================================================================
    
        async holdBonuses(){
      if (this.depositBonuses.items.length === 0 ) {
          await this.$store.dispatch('bonuses/awaitGetDepositBonuses');
        }
      if (this.spinsBonuses.items.length === 0) {
        await this.$store.dispatch('bonuses/awaitGetSpinsBonuses');
      }
      this.dep_bonuses = [
      { text: this.$t("none"), value: 0 },
      ...this.depositBonuses.items.map((bonus) => ({
        text: this.formatDepositBonus(bonus),
        value: bonus.id,
      })),
    ];
    this.fr_bonuses = [
      { text: this.$t("none"), value: 0 }, // "None"
      ...this.spinsBonuses.items.map((bonus) => ({
        text: this.formatSpinsBonus(bonus),
        value: bonus.id,
      })),
    ];
    },
        handleSubmit(validate) {
            validate().then((success) => {
                if (success) {
                    this.createPlayerLevel();
                }
            })
        },
        async createPlayerLevel() {
            this.formLoading = true;
      this.loadingFlag = true;
      this.noBonusFlag = false;
      this.noLogoFlag = false;
      const create = this.createLevel;
      if (!create.logo) {
        this.noLogoFlag = true;
        this.formLoading = false;
        this.loadingFlag = false;
        return;
      }
      if (create.dep_bonus_id === '' && create.free_rounds_bonus_id === '') {
        this.formLoading = false;
        this.loadingFlag = false;
        this.noBonusFlag = true;
        return
      }
      const data = new FormData();
      data.append("name", create.name || '');
      data.append("description", create.description || '');
      data.append("next_level_amount", JSON.stringify(create.next_level_amount) || []);
      data.append("is_active", create.is_active || false);
      data.append("order", Number(create.name) || 0);
      if (create.dep_bonus_id !== '' && create.dep_bonus_id !== 0) {
        // data.dep_bonus_id = create.dep_bonus_id;
        data.append("dep_bonus_id", create.dep_bonus_id || null);
      }

      // add free_rounds_bonus_id, if have
      if (create.free_rounds_bonus_id !== '' && create.free_rounds_bonus_id !== 0) {
        // data.free_rounds_bonus_id = create.free_rounds_bonus_id;
        data.append("free_rounds_bonus_id", create.free_rounds_bonus_id || null);
      }
      if (!create.logo) {
        data.append("logo", null);
      } else if (create.logo !== null && typeof create.logo !== 'string') {
          data.append("logo", create.logo);
        } else if(create.logo.includes("https")){
          data.append("logo", create.logo);
        }
      
        try {
          await this.$store.dispatch("levels/awaitCreateLevel", data);
          this.$toast.add({
                          severity: 'success', summary: this.$t("level_created"), life: 4000,
                        })
                        this.deleteImage();
                        await this.getLevels();
                        this.createLevel = {
          name: "",
          next_level_amount: {
          },
          logo: "",
          is_active: true,
          dep_bonus_id: '',
          free_rounds_bonus_id: '',
          reward_description: "",
          },
          this.selectedCurrencies = [];
        this.$router.push('/player-levels');
      } catch (error) {
        if (error.response && error.response.status === 409) {
            this.createLevel.name = '';
            this.$toast.add({
        severity: 'error', summary: this.$t("level_already_exists"),
        life: 4000,
                      })
        } else if( error.response && error.response.status === 400 && error.response.data.detail.includes('Missing currencies in dep_bonus') ){
            this.createLevel.dep_bonus_id = '';
            this.$toast.add({
        severity: 'error', summary: this.$t("deposit_bonus_no_currencies"),
        life: 6000,
                      })
        } else if( error.response && error.response.status === 400 && error.response.data.detail.includes('Missing currencies in bonus') ){
            this.createLevel.free_rounds_bonus_id = '';
            this.$toast.add({
        severity: 'error', summary: this.$t("freespins_bonus_no_currencies"),
        life: 6000,
                      })
        } else{

            this.$toast.add({
            severity: 'error', summary: this.$t("just_error"),
            detail: error.message,
            life: 4000,
                          })
        }
      } finally{
          this.formLoading = false;
      }
        },
        async dispatchSpinsBonuses() {
            try {
                await this.$store.dispatch('bonuses/awaitGetDepositBonuses');
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: this.$t('just_error'),
                    detail: error,
                    life: 4000 });  
            }
        },
        //========================================================================================================================================================
        
         // Level formated bonuses
            formatDepositBonus(bonus) {
            if (!bonus || !bonus.currency_limits) {
                return;
            }
            const formattedBonus = Object.entries(bonus.currency_limits)
                .map(([currency, limits]) => ` ${currency}: ${this.$t('min_dep')}: ${limits.min_dep}, ${this.$t('max_dep')}: ${limits.max_dep}`);
            const restOfName = [
                this.$t('bonus_percent') + ': ' + bonus.bonus_percent,
                this.$t('wager') + ': ' + bonus.wager,
                this.$t('type') + ': ' + this.$t(bonus.type),
            ];
            return [...restOfName, ...formattedBonus].join(', ');
            },
            formatSpinsBonus(bonus) {
            if (!bonus || !bonus.currency_bet) {
                return;
            }
            const formattedBonus = Object.entries(bonus.currency_bet)
                .filter(([currency, value]) => value !== 0)
                .map(([currency, value]) => this.$t('bet') + ': ' + value + ' ' + currency);
            const restOfName = [
                this.$t('game_name') + ': ' + bonus.game_name,
                this.$t('provider') + ': ' + bonus.provider_name,
                this.$t('spin') + ': ' + bonus.count,
            ];
            const restOfLast = [
                this.$t('wager') + ': ' + bonus.wager,
                this.$t('type') + ': ' + this.$t(bonus.type),
            ];
            return [...restOfName, ...restOfLast, ...formattedBonus].join(', ');
            },
        //========================================================================================================================================================
        
        // Level logo file
    changeLevelFile(event) {
      const file = event.target.files[0];
        if (file && file.size <= 15000000) {
          this.logoLevelPreview = URL.createObjectURL(file);
          this.logoLevelExceed = false;
          this.createLevel.logo = file;
      } else {
        this.logoLevelExceed = true;
        this.logoLevelPreview = null;
        event.target.value = null;
      }
      
      
    },
    deleteImage() {
      URL.revokeObjectURL(this.logoLevelPreview);
      this.logoLevelPreview = null;
      this.createLevel.logo = null;
      this.$refs.imagePreview.value = '';
    },
    triggerInput() {
        if (!this.logoLevelPreview) {
            document.getElementById('create-level').click();
        }
    },
    },
}
</script>

<style lang="scss" scoped>
.form-block{
    //max-height: 214px;
    //overflow: auto;
    display: flex;
    flex-direction: column;
    span{
      display: flex;
      align-items: center;
    }
    .title-input {
      flex: 0 0 200px;
      display: flex;
      //justify-content: flex-end;
      @media (max-width:500px){
        flex: 0 0 100px;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
      }
    }
    .wrapper-input {
      //flex: 0 0 200px;
     // width: 100%;
    flex: 1 1 auto;
    @media (max-width:500px){
      flex: 1 1 100%;
    }
    }
  }

  .load-photo {
    margin-bottom: 6px;
    align-items: center;
    background: #f2f6f9 !important;
    border: 2px dashed #dfe4e8 !important;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 5px !important;
    &_provider{
      background: #0000009e !important;
      border: 2px dashed #000 !important;
    }
    span {
      cursor: pointer;
    }
  }
  .action-buttons {
    gap: 5px;
    &__button {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 25px;
      flex: 0 0 30px;
      box-shadow: 0 0 5px rgba(67, 197, 28, 0.3);
      border-radius: 4px;
      i {
        font-size: 18px;
      }
    }
  }
  .error-message-enter-active, .error-message-leave-active {
    transition: opacity 0.3s linear;
  }
  
  .error-message-enter, .error-message-leave-to {
    opacity: 0;
  }
  
  .error-message-leave-active {
    opacity: 0;
  }
  
  .error-message-enter-to, .error-message-leave {
    opacity: 1;
  }
  .error-message{
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  color: var(--red);
  margin-bottom: 28px;
  font-weight: 500;
  &._no-bonus{
    margin-bottom: 8px;
  }
  }
</style>